import React, { Component, useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import Chart from "react-apexcharts";
import {
  CohortInterface,
  FC_GetGeneralReportDetails,
  GenderEnum,
  ProgressReportInterface,
} from "../../actions";
import SelectCustom from "../SelectCustom/SelectCustom";
import { TrainingInterface } from "../TrainingItem/TrainingItem";
import ReactApexChart from "react-apexcharts";
import SearchInput from "../Fragments/SearchInput";
import ExportToExcel from "../GenerateReport/ExportToExcel";
import { search } from "../../utils/functions";
import LoaderComponent from "../Loading/LoaderComponent";
import { MdNotes, MdVerifiedUser } from "react-icons/md";
import { TbSum } from "react-icons/tb";
import { TotalAverageMarks } from "./TotalAverageMarks";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaChalkboardTeacher } from "react-icons/fa";
import TableWithPagination from "../TableWithPagination/TableWithPagination";

export enum GeneralReportTypeEnum {
  courseNavigation = "courseNavigation",
  endOfChapter = "endOfChapter",
  copMarks = "copMarks",
  classroomApplication = "classroomApplication",
  selfAssesment = "selfAssesment",
  endOfModule = "endOfModule",
  endOfCourse = "endOfCourse",
  reflectionNotes = "reflectionNotes",
  selfStudy = "selfStudy",
  coaching = "coaching",
  teacherPractices = "teacherPractices",
  TotalAverage = "TotalAverage",
}

enum ReportType {
  Graph = "Graph",
  Table = "Table",
}

interface AgeRangeInterface {
  id: string;
  start: number;
  end: number;
}

const AgeRanges: AgeRangeInterface[] = [
  {
    id: "1",
    start: 18,
    end: 30,
  },
  {
    id: "2",
    start: 31,
    end: 45,
  },
  {
    id: "3",
    start: 46,
    end: 60,
  },
  {
    id: "4",
    start: 61,
    end: 90,
  },
];

const FilterItem = (props: {
  title: string;
  selected: { key: string; value: string } | null;
  data: { key: string; value: string }[];
  onChange: (data: { key: string; value: string }) => void;
}): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className="relative">
      <div
        onClick={() => setOpen(!open)}
        className={`px-3 py-2 pr-2 cursor-pointer hover:border-accent-600 rounded-md border ${
          props.selected !== null ? "border-accent-800" : ""
        } flex flex-row items-center justify-between gap-4`}
      >
        <span className="truncate">
          {props.selected === null ? (
            props.title
          ) : (
            <span className="font-semibold">{props.selected.value}</span>
          )}
        </span>
        <div>
          {open === false ? (
            <RiArrowDropDownLine className="text-xl" />
          ) : (
            <RiArrowDropUpLine className="text-xl" />
          )}
        </div>
      </div>
      {open === true && (
        <div className="absolute z-20 right-0 pt-1 animate__animated animate__fadeInRight animate__faster">
          <SelectCustom
            loading={false}
            id={"key"}
            title={"value"}
            close={() => setOpen(false)}
            data={[{ key: null, value: "View All" }, ...props.data]}
            click={(data: { key: string; value: string }) => {
              setOpen(false);
              props.onChange(data);
            }}
          />
        </div>
      )}
    </div>
  );
};

interface ElearningReportProps {
  training: TrainingInterface;
  cohorts: CohortInterface[];
}
interface ElearningReportState {
  selectedTab: GeneralReportTypeEnum;
  reportType: ReportType;
  selectedDistrict: { district_code: string; district_name: string } | null;
  selectedSector: { sector_code: string; sector_name: string } | null;
  selectedSchool: { school_code: string; school_name: string } | null;
  selectedGender: GenderEnum | null;
  selectedAge: AgeRangeInterface | null;
  loading: boolean;
  reportDetails: ProgressReportInterface[] | null;
  error: string;
  searchValue: string;
  selectedModule: { module_id: string; module_name: string } | null;
  openSelectModule: boolean;
  selectedUserReport: ProgressReportInterface | null;
  downloadUrl: string;
  success: string;
}

export class ElearningReport extends Component<
  ElearningReportProps,
  ElearningReportState
> {
  constructor(props: ElearningReportProps) {
    super(props);
    this.state = {
      selectedTab: GeneralReportTypeEnum.courseNavigation,
      reportType: ReportType.Graph,
      selectedDistrict: null,
      selectedSchool: null,
      selectedGender: null,
      selectedAge: null,
      loading: false,
      reportDetails: null,
      error: "",
      selectedSector: null,
      searchValue: "",
      selectedModule: null,
      openSelectModule: false,
      selectedUserReport: null,
      downloadUrl: "",
      success: "",
    };
  }
  loadReportDetails = () => {
    this.setState({ loading: true });
    FC_GetGeneralReportDetails(
      this.props.training.trainingId,
      (
        loading: boolean,
        res: {
          msg: string;
          type: "SUCCESS" | "ERROR";
          data: ProgressReportInterface[];
        } | null
      ) =>
        this.setState({
          loading: loading,
          reportDetails: res?.type === "SUCCESS" ? res.data : [],
          error: res?.type === "ERROR" ? res.msg : "",
        })
    );
  };

  FilteredData = () => {
    if (this.state.reportDetails === null) {
      return [];
    }
    var response = this.state.reportDetails;
    if (this.state.selectedDistrict !== null) {
      response = response.filter(
        (itm) =>
          this.state.selectedDistrict !== null &&
          itm.district_code.toString() ===
            this.state.selectedDistrict.district_code.toString()
      );
    }
    if (this.state.selectedSector !== null) {
      response = response.filter(
        (itm) =>
          this.state.selectedSector !== null &&
          itm.sector_code.toString() ===
            this.state.selectedSector.sector_code.toString()
      );
    }
    if (this.state.selectedSchool !== null) {
      response = response.filter(
        (itm) =>
          this.state.selectedSchool !== null &&
          itm.school_code.toString() ===
            this.state.selectedSchool.school_code.toString()
      );
    }
    return search(
      response,
      this.state.searchValue
    ) as ProgressReportInterface[];
  };

  getTotalBy = (
    dataSelected: string,
    type: GeneralReportTypeEnum,
    gender: GenderEnum | null
  ) => {
    if (this.state.reportDetails !== null) {
      var temp: ProgressReportInterface[] = this.FilteredData().filter(
        (itm) =>
          gender === null ||
          (itm.gender !== null &&
            itm.gender.toLowerCase() === gender.toLowerCase())
      );
      if (type === GeneralReportTypeEnum.courseNavigation) {
        return temp.filter(
          (itm) => itm.courseNavigation.toString() === dataSelected.toString()
        ).length;
      }
      if (type === GeneralReportTypeEnum.endOfChapter) {
        return temp.filter(
          (itm) => itm.endOfChapter.toString() === dataSelected.toString()
        ).length;
      }
      if (type === GeneralReportTypeEnum.copMarks) {
        return temp.filter(
          (itm) => itm.copMarks.toString() === dataSelected.toString()
        ).length;
      }
      if (type === GeneralReportTypeEnum.classroomApplication) {
        return temp.filter(
          (itm) =>
            itm.classroomApplication.toString() === dataSelected.toString()
        ).length;
      }
      if (type === GeneralReportTypeEnum.endOfCourse) {
        return temp.filter(
          (itm) => itm.endOfCourse.toString() === dataSelected.toString()
        ).length;
      }
      if (type === GeneralReportTypeEnum.reflectionNotes) {
        return temp.filter(
          (itm) => itm.reflectionNotes.toString() === dataSelected.toString()
        ).length;
      }
      if (type === GeneralReportTypeEnum.selfAssesment) {
        return temp.filter(
          (itm) => itm.selfAssesment.toString() === dataSelected.toString()
        ).length;
      }
      if (type === GeneralReportTypeEnum.endOfModule) {
        return temp.filter(
          (itm) => itm.endOfModule.toString() === dataSelected.toString()
        ).length;
      }
      if (type === GeneralReportTypeEnum.teacherPractices) {
        return temp.filter(
          (itm) =>
            this.getTeacherPractice(itm).toString() === dataSelected.toString()
        ).length;
      }
    }
    return 0;
  };

  getReportDetails = (
    tab: GeneralReportTypeEnum,
    gender: GenderEnum | null
  ) => {
    if (this.state.reportDetails === null) {
      return [];
    }
    var response: { count: string; total: number }[] = [];
    // COMPLETION

    var counts: string[] = [];
    for (const item of this.FilteredData().filter(
      (itm) =>
        gender === null ||
        (itm.gender !== null &&
          itm.gender.toLowerCase() === gender.toLowerCase())
    )) {
      switch (tab) {
        case GeneralReportTypeEnum.courseNavigation:
          if (
            counts.find(
              (itm) => itm.toString() === item.courseNavigation.toString()
            ) === undefined
          ) {
            counts = [...counts, item.courseNavigation.toString()];
          }
          break;
        case GeneralReportTypeEnum.copMarks:
          if (
            counts.find(
              (itm) => itm.toString() === item.copMarks.toString()
            ) === undefined
          ) {
            counts = [...counts, item.copMarks.toString()];
          }
          break;
        case GeneralReportTypeEnum.endOfChapter:
          if (
            counts.find(
              (itm) => itm.toString() === item.endOfChapter.toString()
            ) === undefined
          ) {
            counts = [...counts, item.endOfChapter.toString()];
          }
          break;
        case GeneralReportTypeEnum.classroomApplication:
          if (
            counts.find(
              (itm) => itm.toString() === item.classroomApplication.toString()
            ) === undefined
          ) {
            counts = [...counts, item.classroomApplication.toString()];
          }
          break;
        case GeneralReportTypeEnum.selfAssesment:
          if (
            counts.find(
              (itm) => itm.toString() === item.selfAssesment.toString()
            ) === undefined
          ) {
            counts = [...counts, item.selfAssesment.toString()];
          }
          break;
        case GeneralReportTypeEnum.endOfModule:
          if (
            counts.find(
              (itm) => itm.toString() === item.endOfModule.toString()
            ) === undefined
          ) {
            counts = [...counts, item.endOfModule.toString()];
          }
          break;
        case GeneralReportTypeEnum.endOfCourse:
          if (
            counts.find(
              (itm) => itm.toString() === item.endOfCourse.toString()
            ) === undefined
          ) {
            counts = [...counts, item.endOfCourse.toString()];
          }
          break;
        case GeneralReportTypeEnum.reflectionNotes:
          if (
            counts.find(
              (itm) => itm.toString() === item.reflectionNotes.toString()
            ) === undefined
          ) {
            counts = [...counts, item.reflectionNotes.toString()];
          }
          break;
        case GeneralReportTypeEnum.teacherPractices:
          if (
            counts.find(
              (itm) =>
                itm.toString() === this.getTeacherPractice(item).toString()
            ) === undefined
          ) {
            counts = [...counts, this.getTeacherPractice(item).toString()];
          }
          break;
        default:
          if (
            counts.find(
              (itm) => itm.toString() === item.classroomApplication.toString()
            ) === undefined
          ) {
            counts = [...counts, item.classroomApplication.toString()];
          }
      }
    }
    response = counts.map((item) => ({
      count: item,
      total: this.getTotalBy(item, tab, gender),
    }));
    return response;
  };
  getTotalsByGender = (gender: GenderEnum) => {
    var total: number = 0;
    for (const item of this.getReportDetails(this.state.selectedTab, gender)) {
      total += item.total;
    }
    return total;
  };
  getDistrictsList = () => {
    if (this.state.reportDetails === null) {
      return [];
    }
    var response: {
      district_code: string;
      district_name: string;
    }[] = [];
    for (const item of this.state.reportDetails) {
      if (
        response.find(
          (itm) =>
            itm.district_code.toString() === item.district_code.toString()
        ) === undefined
      ) {
        response.push({
          district_code: item.district_code,
          district_name: item.district_name,
        });
      }
    }
    return response;
  };
  getSectorsByDistrict = (district_code: string) => {
    if (this.state.reportDetails === null) {
      return [];
    }
    var response: {
      sector_code: string;
      sector_name: string;
    }[] = [];
    for (const item of this.state.reportDetails.filter(
      (itm) => itm.district_code.toString() === district_code.toString()
    )) {
      if (
        response.find(
          (itm) => itm.sector_code.toString() === item.sector_code.toString()
        ) === undefined
      ) {
        response.push({
          sector_code: item.sector_code,
          sector_name: item.sector_name,
        });
      }
    }
    return response;
  };
  getSchoolsList = (district_code: string, sector_code: string | null) => {
    if (this.state.reportDetails === null) {
      return [];
    }
    var response: { school_code: string; school_name: string }[] = [];
    for (const item of this.state.reportDetails.filter(
      (itm) =>
        itm.district_code.toString() === district_code.toString() &&
        (sector_code === null ||
          itm.sector_code.toString() === sector_code.toString())
    )) {
      if (
        response.find(
          (itm) => itm.school_code.toString() === item.school_code.toString()
        ) === undefined
      ) {
        response.push({
          school_code: item.school_code,
          school_name: item.school_name,
        });
      }
    }
    return response;
  };

  componentDidMount(): void {
    this.loadReportDetails();
  }

  getModulesList = () => {
    if (this.state.reportDetails === null) {
      return [];
    }
    var modules: { module_id: string; module_name: string }[] = [];
    for (const item of this.state.reportDetails) {
      if (
        modules.find(
          (itm) => item.moduleId.toString() === itm.module_id.toString()
        ) === undefined
      ) {
        modules.push({
          module_id: item.moduleId,
          module_name: item.moduleName,
        });
      }
    }
    return modules;
  };

  getUnitsByModule = (module_id: string) => {
    if (this.state.reportDetails === null) {
      return [];
    }
    var units: { unit_id: string; unit_name: string }[] = [];
    for (const item of this.state.reportDetails.filter(
      (itm) => itm.moduleId.toString() === module_id.toString()
    )) {
      if (
        units.find(
          (itm) => itm.unit_id.toString() === item.chapterId.toString()
        ) === undefined
      ) {
        units.push({
          unit_id: item.chapterId,
          unit_name: item.chapterName,
        });
      }
    }
    return units;
  };

  getUserModuleValue = (staff_code: string, module_id: string) => {
    var response: {
      courseNavigation: number;
      endOfChapter: number;
      CoPMarks: number;
      classroomApplication: number;
      selfAssesment: number;
      endOfModule: number;
      endOfCourse: number;
      reflectionNotes: number;
      teacherPractices: number;
    } = {
      classroomApplication: 0,
      CoPMarks: 0,
      courseNavigation: 0,
      endOfChapter: 0,
      endOfCourse: 0,
      endOfModule: 0,
      selfAssesment: 0,
      reflectionNotes: 0,
      teacherPractices: 0,
    };
    const selectedData = (this.state.reportDetails ?? []).find(
      (itm) =>
        itm.staff_code === staff_code &&
        itm.moduleId.toString() === module_id.toString()
    );
    if (selectedData !== undefined) {
      response = {
        classroomApplication: parseFloat(selectedData.classroomApplication),
        CoPMarks: parseFloat(selectedData.copMarks),
        courseNavigation: parseFloat(selectedData.courseNavigation),
        endOfChapter: parseFloat(selectedData.endOfChapter),
        endOfCourse: parseFloat(selectedData.endOfCourse),
        endOfModule: parseFloat(selectedData.endOfModule),
        selfAssesment: parseFloat(selectedData.selfAssesment),
        reflectionNotes: parseFloat(selectedData.reflectionNotes),
        teacherPractices: this.getTeacherPractice(selectedData),
      };
    }
    return response;
  };

  getUserUnitValue = (staff_code: string, unit_id: string) => {
    var response: {
      courseNavigation: number;
      endOfChapter: number;
      CoPMarks: number;
      classroomApplication: number;
      selfAssesment: number;
      endOfModule: number;
      endOfCourse: number;
      reflectionNotes: number;
      teacherPractices: number;
    } = {
      classroomApplication: 0,
      CoPMarks: 0,
      courseNavigation: 0,
      endOfChapter: 0,
      endOfCourse: 0,
      endOfModule: 0,
      selfAssesment: 0,
      reflectionNotes: 0,
      teacherPractices: 0,
    };
    const selectedData = (this.state.reportDetails ?? []).find(
      (itm) =>
        itm.staff_code === staff_code &&
        itm.chapterId.toString() === unit_id.toString()
    );
    if (selectedData !== undefined) {
      response = {
        classroomApplication: parseFloat(selectedData.classroomApplication),
        CoPMarks: parseFloat(selectedData.copMarks),
        courseNavigation: parseFloat(selectedData.courseNavigation),
        endOfChapter: parseFloat(selectedData.endOfChapter),
        endOfCourse: parseFloat(selectedData.endOfCourse),
        endOfModule: parseFloat(selectedData.endOfModule),
        selfAssesment: parseFloat(selectedData.selfAssesment),
        reflectionNotes: parseFloat(selectedData.reflectionNotes),
        teacherPractices: this.getTeacherPractice(selectedData),
      };
    }

    return response;
  };

  getCountsRange = () => {
    var response: { start: number; end: number }[] = [];
    var i = 0;
    while (i < 100) {
      response.push({
        start: i,
        end: i + 10,
      });
      i += 10;
    }
    return response.sort((a, b) => a.start - b.start);
  };

  getRangeTotals = (
    start: number,
    end: number,
    tab: GeneralReportTypeEnum,
    gender: GenderEnum | null
  ) => {
    var total: number = 0;
    for (const item of this.getReportDetails(tab, gender)) {
      if (parseFloat(item.count) >= start && parseFloat(item.count) <= end) {
        total += item.total;
      }
    }
    return total;
  };

  tabSelectedUserUnitValues = (staff_code: string, unit_id: string) => {
    switch (this.state.selectedTab) {
      case GeneralReportTypeEnum.courseNavigation:
        return this.getUserUnitValue(staff_code, unit_id).courseNavigation;
      case GeneralReportTypeEnum.copMarks:
        return this.getUserUnitValue(staff_code, unit_id).CoPMarks;
      case GeneralReportTypeEnum.endOfChapter:
        return this.getUserUnitValue(staff_code, unit_id).endOfChapter;
      case GeneralReportTypeEnum.classroomApplication:
        return this.getUserUnitValue(staff_code, unit_id).classroomApplication;
      case GeneralReportTypeEnum.endOfCourse:
        return this.getUserUnitValue(staff_code, unit_id).endOfCourse;
      case GeneralReportTypeEnum.endOfModule:
        return this.getUserUnitValue(staff_code, unit_id).endOfModule;
      case GeneralReportTypeEnum.reflectionNotes:
        return this.getUserUnitValue(staff_code, unit_id).reflectionNotes;
      case GeneralReportTypeEnum.teacherPractices:
        return this.getUserUnitValue(staff_code, unit_id).teacherPractices;
      default:
        return this.getUserUnitValue(staff_code, unit_id).selfAssesment;
    }
  };

  getAgeRangeValues = (start: number, end: number) => {
    return (this.state.reportDetails ?? []).filter(
      (itm) =>
        itm.age !== null &&
        parseFloat(itm.age) >= start &&
        parseFloat(itm.age) <= end
    ).length;
  };

  getUserReport = (staff_code: string) =>
    (this.state.reportDetails ?? []).filter(
      (itm) => itm.staff_code === staff_code
    );
  getItemAverage = (data: ProgressReportInterface) =>
    (
      (parseFloat(data.courseNavigation) +
        parseFloat(data.endOfChapter) +
        parseFloat(data.copMarks) +
        parseFloat(data.classroomApplication) +
        parseFloat(data.endOfCourse) +
        parseFloat(data.endOfModule) +
        parseFloat(data.selfAssesment) +
        parseFloat(data.reflectionNotes)) /
      8
    ).toFixed(1);
  getAverageUserReport = (staff_code: string) => {
    var total = 0;
    for (const item of this.getUserReport(staff_code)) {
      total += parseFloat(this.getItemAverage(item));
    }
    return (total / this.getUserReport(staff_code).length).toFixed(1);
  };

  getTeacherPractice = (data: ProgressReportInterface) =>
    parseFloat(
      (
        (parseFloat(data.copMarks) +
          parseFloat(data.classroomApplication) +
          parseFloat(data.reflectionNotes) +
          parseFloat(data.selfStudy) +
          parseFloat(data.coaching)) /
        5
      ).toFixed(1)
    );
  getGenderMarksPercentage = (
    gender: GenderEnum,
    tab: GeneralReportTypeEnum
  ) => {
    // const expected_marks: number = this.FilteredData().length * 100;
    var total_marks: number = 0;
    for (const item of this.FilteredData().filter(
      (itm) =>
        gender === null ||
        (itm.gender !== null &&
          itm.gender.toLowerCase() === gender.toLowerCase())
    )) {
      switch (tab) {
        case GeneralReportTypeEnum.courseNavigation:
          total_marks += parseFloat(item.courseNavigation);
          break;
        case GeneralReportTypeEnum.endOfChapter:
          total_marks += parseFloat(item.endOfChapter);
          break;
        case GeneralReportTypeEnum.endOfModule:
          total_marks += parseFloat(item.endOfModule);
          break;
        case GeneralReportTypeEnum.endOfCourse:
          total_marks += parseFloat(item.endOfCourse);
          break;
        case GeneralReportTypeEnum.selfAssesment:
          total_marks += parseFloat(item.selfAssesment);
          break;
        default:
          total_marks += this.getTeacherPractice(item);
          break;
      }
    }
    return total_marks;
  };

  getAgesMarksPercentage = (
    tab: GeneralReportTypeEnum,
    start: number,
    end: number
  ) => {
    // const expected_marks: number = this.FilteredData().length * 100;
    var total_marks: number = 0;
    for (const item of this.FilteredData().filter(
      (itm) =>
        itm.age !== null &&
        parseFloat(itm.age) >= start &&
        parseFloat(itm.age) <= end
    )) {
      switch (tab) {
        case GeneralReportTypeEnum.courseNavigation:
          total_marks += parseFloat(item.courseNavigation);
          break;
        case GeneralReportTypeEnum.endOfChapter:
          total_marks += parseFloat(item.endOfChapter);
          break;
        case GeneralReportTypeEnum.endOfModule:
          total_marks += parseFloat(item.endOfModule);
          break;
        case GeneralReportTypeEnum.endOfCourse:
          total_marks += parseFloat(item.endOfCourse);
          break;
        case GeneralReportTypeEnum.selfAssesment:
          total_marks += parseFloat(item.selfAssesment);
          break;
        default:
          total_marks += this.getTeacherPractice(item);
          break;
      }
    }
    return total_marks;
  };

  render() {
    if (this.state.loading === true || this.state.reportDetails === null) {
      return (
        <div className="p-3 pt-0 bg-gray-100">
          <LoaderComponent />
        </div>
      );
    }
    const tabTile =
      this.state.selectedTab === GeneralReportTypeEnum.courseNavigation
        ? "Course Navigation"
        : this.state.selectedTab === GeneralReportTypeEnum.endOfChapter
        ? "End of Chapter"
        : this.state.selectedTab === GeneralReportTypeEnum.copMarks
        ? "CoP Marking"
        : this.state.selectedTab === GeneralReportTypeEnum.classroomApplication
        ? "Classroom Practices Ideas"
        : this.state.selectedTab === GeneralReportTypeEnum.endOfModule
        ? "End of Module"
        : this.state.selectedTab === GeneralReportTypeEnum.endOfCourse
        ? "End of Course"
        : this.state.selectedTab === GeneralReportTypeEnum.reflectionNotes
        ? "Answers to Reflection"
        : this.state.selectedTab === GeneralReportTypeEnum.selfStudy
        ? "Self Study Ideas"
        : this.state.selectedTab === GeneralReportTypeEnum.coaching
        ? "Ideas on Coaching"
        : this.state.selectedTab === GeneralReportTypeEnum.teacherPractices
        ? "Teacher Practices"
        : "selfAssesment";

    return (
      <div className="p-3 bg-gray-100">
        <div className="flex flex-row items-center justify-between gap-2 mb-2">
          <div className="w-full">
            <div className="font-bold mb-3 text-lg">General Reports</div>
            <div className="grid grid-cols-7 text-sm gap-2">
              {[
                {
                  key: GeneralReportTypeEnum.courseNavigation,
                  value: "Course Navigation",
                  icon: IoIosCheckmarkCircle,
                },
                {
                  key: GeneralReportTypeEnum.endOfChapter,
                  value: "End of Chapter",
                  icon: MdNotes,
                },
                {
                  key: GeneralReportTypeEnum.selfAssesment,
                  value: "Self Assessment",
                  icon: MdVerifiedUser,
                },
                {
                  key: GeneralReportTypeEnum.endOfModule,
                  value: "End of Module",
                  icon: MdVerifiedUser,
                },
                {
                  key: GeneralReportTypeEnum.endOfCourse,
                  value: "End of Course",
                  icon: MdVerifiedUser,
                },
                {
                  key: GeneralReportTypeEnum.teacherPractices,
                  value: "Teacher Practices",
                  icon: FaChalkboardTeacher,
                },

                {
                  key: GeneralReportTypeEnum.TotalAverage,
                  value: "Average Marks",
                  icon: TbSum,
                },
              ].map((tab, t) => (
                <div
                  key={t + 1}
                  onClick={() => this.setState({ selectedTab: tab.key })}
                  className={`col-span-6 md:col-span-1 lg:col-span-1 px-3 py-3 cursor-pointer rounded-lg h-full ${
                    this.state.selectedTab === tab.key
                      ? "border bg-white border-accent-800 text-black animate__animated animate__bounceIn animate__faster"
                      : "bg-white hover:text-black border border-white hover:border-accent-600"
                  } flex flex-col items-center justify-center gap-1 text-center group`}
                >
                  <div>
                    <tab.icon
                      className={`text-2xl ${
                        this.state.selectedTab !== tab.key
                          ? "text-primary-700 group-hover:text-gray-400"
                          : "text-green-500"
                      }`}
                    />
                  </div>
                  <span className="text-xs">{tab.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {this.state.selectedTab !== GeneralReportTypeEnum.TotalAverage ? (
          <>
            <div className="flex flex-row items-center justify-between gap-2 rounded-md bg-white p-2 mb-2">
              <div className="flex flex-row items-center gap-2 text-sm">
                {[
                  {
                    key: ReportType.Graph,
                    value: "Graph",
                  },
                  {
                    key: ReportType.Table,
                    value: "Tabular",
                  },
                ].map((item, i) => (
                  <div
                    onClick={() =>
                      this.setState({ reportType: item.key, searchValue: "" })
                    }
                    key={i + 1}
                    className={`px-4 py-2 cursor-pointer rounded-md font-semibold ${
                      this.state.reportType === item.key
                        ? "bg-accent-200 text-accent-900 animate__animated animate__bounceIn animate__faster"
                        : "border-white hover:bg-accent-200 hover:text-black text-gray-800"
                    }`}
                  >
                    {item.value}
                  </div>
                ))}
              </div>
              <div className="flex flex-row items-center gap-2 text-sm">
                <FilterItem
                  title="Choose District"
                  selected={
                    this.state.selectedDistrict === null
                      ? null
                      : {
                          key: this.state.selectedDistrict.district_code,
                          value: this.state.selectedDistrict.district_name,
                        }
                  }
                  data={this.getDistrictsList().map((item) => ({
                    key: item.district_code,
                    value: item.district_name,
                  }))}
                  onChange={(data: { key: string; value: string }) =>
                    this.setState({
                      selectedDistrict:
                        data.key === null
                          ? null
                          : {
                              district_code: data.key,
                              district_name: data.value,
                            },
                    })
                  }
                />
                <FilterItem
                  title="Choose Sector"
                  selected={
                    this.state.selectedSector === null
                      ? null
                      : {
                          key: this.state.selectedSector.sector_code,
                          value: this.state.selectedSector.sector_name,
                        }
                  }
                  data={
                    this.state.selectedDistrict === null
                      ? []
                      : this.getSectorsByDistrict(
                          this.state.selectedDistrict.district_code
                        ).map((item) => ({
                          key: item.sector_code,
                          value: item.sector_name,
                        }))
                  }
                  onChange={(data: { key: string; value: string }) =>
                    this.setState({
                      selectedSector:
                        data.key === null
                          ? null
                          : {
                              sector_code: data.key,
                              sector_name: data.value,
                            },
                    })
                  }
                />
                <FilterItem
                  title="Choose School"
                  selected={
                    this.state.selectedSchool === null
                      ? null
                      : {
                          key: this.state.selectedSchool.school_code,
                          value: this.state.selectedSchool.school_name,
                        }
                  }
                  data={
                    this.state.selectedDistrict === null
                      ? []
                      : this.getSchoolsList(
                          this.state.selectedDistrict.district_code,
                          this.state.selectedSector === null
                            ? null
                            : this.state.selectedSector.sector_code
                        ).map((item) => ({
                          key: item.school_code,
                          value: item.school_name,
                        }))
                  }
                  onChange={(data: { key: string; value: string }) =>
                    this.setState({
                      selectedSchool:
                        data.key === null
                          ? null
                          : {
                              school_code: data.key,
                              school_name: data.value,
                            },
                    })
                  }
                />
              </div>
            </div>
            {this.state.reportType === ReportType.Graph && (
              <div className="flex flex-row gap-3">
                <div className="mb-2">
                  <div
                    className="p-3 bg-white rounded-md"
                    style={{ height: "fit-content" }}
                  >
                    <div className="mb-2 font-bold pl-3">
                      {tabTile} by Gender
                    </div>
                    <ReactApexChart
                      options={{
                        labels: ["MALE", "FEMALE"],
                        chart: {
                          type: "donut",
                          //   width: 100, // Set the width of the chart
                          //   height: "50px", // Set the height of the chart
                          background: "transparent",
                        },
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 100,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                        theme: {
                          mode: "light",
                        },
                        colors: ["#1999d1", "#ff9a2f"],
                        fill: {
                          colors: ["#1999d1", "#ff9a2f"],
                        },
                        dataLabels: {
                          style: {
                            colors: ["#fff", "#fff"],
                          },
                        },
                      }}
                      series={[
                        this.getGenderMarksPercentage(
                          GenderEnum.MALE,
                          this.state.selectedTab
                        ),
                        this.getGenderMarksPercentage(
                          GenderEnum.FEMALE,
                          this.state.selectedTab
                        ),
                      ]}
                      type="donut"
                      height={350}
                      width={300}
                    />
                  </div>
                  <div
                    className="p-3 bg-white rounded-md mt-3"
                    style={{ height: "fit-content" }}
                  >
                    <div className="mb-2 font-bold pl-3">{tabTile} by Age</div>
                    <ReactApexChart
                      options={{
                        labels: AgeRanges.map(
                          (item) => `Age ${item.start} - ${item.end}`
                        ),
                        chart: {
                          type: "donut",
                          background: "transparent",
                        },
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 100,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                        theme: {
                          mode: "light",
                        },
                      }}
                      series={AgeRanges.map((item) =>
                        this.getAgesMarksPercentage(
                          this.state.selectedTab,
                          item.start,
                          item.end
                        )
                      )}
                      type="donut"
                      height={350}
                      width={300}
                    />
                  </div>
                </div>
                <div className="p-3 bg-white rounded-md w-full">
                  <div className="font-bold pl-3">{tabTile} Details</div>
                  <Chart
                    options={{
                      chart: {
                        // height: 350,
                        type: "bar",
                        stacked: false,
                      },
                      stroke: {
                        width: [0, 2, 5],
                        curve: "smooth",
                      },
                      plotOptions: {
                        bar: {
                          columnWidth: "100%",
                          horizontal: false,
                          borderRadius: 0,
                          barHeight: 24,
                        },
                      },

                      fill: {
                        //   opacity: [0.85, 0.25, 1],
                        gradient: {
                          inverseColors: false,
                          shade: "light",
                          type: "vertical",
                          // opacityFrom: 0.85,
                          // opacityTo: 0.55,
                          stops: [0, 100, 100, 100],
                        },
                      },
                      labels: this.getCountsRange().map(
                        (item) => `${item.start} - ${item.end}`
                      ),
                      markers: {
                        size: 0,
                      },
                      xaxis: {
                        type: "category",
                      },
                      yaxis: {
                        title: {
                          text: `${tabTile}`,
                        },
                        min: 0,
                      },
                      tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                          formatter: function (y) {
                            if (typeof y !== "undefined") {
                              return y.toFixed(0) + "";
                            }
                            return y;
                          },
                        },
                      },
                    }}
                    series={[
                      {
                        name: "MALE",
                        type: "bar",
                        data: this.getCountsRange().map((item) =>
                          this.getRangeTotals(
                            item.start,
                            item.end,
                            this.state.selectedTab,
                            GenderEnum.MALE
                          )
                        ),
                        color: "#1999d1",
                      },
                      {
                        name: "FEMALE",
                        type: "bar",
                        data: this.getCountsRange().map((item) =>
                          this.getRangeTotals(
                            item.start,
                            item.end,
                            this.state.selectedTab,
                            GenderEnum.FEMALE
                          )
                        ),
                        color: "#ff9a2f",
                      },
                    ]}
                    type="bar"
                    height={"600px"}
                  />
                </div>
              </div>
            )}
            {this.state.reportType === ReportType.Table && (
              <div className="p-1 bg-white rounded-md">
                <div className="">
                  <div className="font-bold text-lg mb-2 px-3 pt-2">
                    Tabular Data
                  </div>
                  <div className="flex flex-row items-center justify-between w-full gap-2 mb-3 px-1">
                    <SearchInput
                      searchData={this.state.searchValue}
                      onChange={(value: string) =>
                        this.setState({ searchValue: value })
                      }
                    />
                    <ExportToExcel
                      fileData={this.FilteredData().map((item, i) => ({
                        "#": i + 1,
                        "Staff Code": item.staff_code,
                        "Full Name": item.traineeName,
                        Gender: item.gender,
                        Age: item.age,
                        Disability:
                          item.disability.toString() === "1" ? "True" : "False",
                        District: item.district_name,
                        Sector: item.sector_name,
                        "School Code": item.school_code,
                        School: item.school_name,
                        Module: item.moduleName,
                        Unit: item.chapterName,
                        "Unit Value":
                          this.state.selectedTab ===
                          GeneralReportTypeEnum.courseNavigation
                            ? `${item.courseNavigation}%`
                            : this.state.selectedTab ===
                              GeneralReportTypeEnum.copMarks
                            ? item.copMarks
                            : this.state.selectedTab ===
                              GeneralReportTypeEnum.endOfChapter
                            ? item.endOfChapter
                            : this.state.selectedTab ===
                              GeneralReportTypeEnum.classroomApplication
                            ? item.classroomApplication
                            : this.state.selectedTab ===
                              GeneralReportTypeEnum.selfAssesment
                            ? item.selfAssesment
                            : this.state.selectedTab ===
                              GeneralReportTypeEnum.endOfModule
                            ? item.endOfModule
                            : this.state.selectedTab ===
                              GeneralReportTypeEnum.reflectionNotes
                            ? item.reflectionNotes
                            : item.endOfCourse,
                      }))}
                      fileName={`${tabTile} report details`}
                      btnName="Download Report"
                    />
                  </div>
                </div>
                <div className="w-full overflow-x-auto">
                  <TableWithPagination
                    data={this.FilteredData().map((item, i) => ({
                      "#": i + 1,
                      staff_code: item.staff_code,
                      traineeName: item.traineeName,
                      moduleName: item.moduleName,
                      chapterName: item.chapterName,
                      tabTile: this.tabSelectedUserUnitValues(
                        item.staff_code,
                        item.chapterId
                      ),
                      gender: item.gender,
                      age: item.age,
                      disability:
                        item.disability.toString() === "1" ? "True" : "False",
                      district_name: item.district_name,
                      sector_name: item.sector_name,
                      school_code: item.school_code,
                      school_name: item.school_name,
                    }))}
                    headerTitles={[
                      "#",
                      "Staff Code",
                      "Full Name",
                      "Module",
                      "Chapter",
                      tabTile,
                      "Gender",
                      "Age",
                      "Disability",
                      "District",
                      "Sector",
                      "School Code",
                      "School Name",
                    ]}
                    itemsPerPage={10}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <TotalAverageMarks
              data={this.state.reportDetails}
              cohorts={this.props.cohorts}
              training={this.props.training}
            />
          </div>
        )}
      </div>
    );
  }
}
