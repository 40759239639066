import React, { Fragment, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  Auth,
  FC_CheckLoggedIn,
  FC_GetSystemInfo,
  FC_Logout,
  System,
  FC_SetError,
  FC_SetSuccess,
} from "./actions";
import { StoreState } from "./reducers";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LazyLoading from "./components/LazyLoading/LazyLoading";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { Homepage } from "./containers/Homepage/Homepage";
import MainLoading from "./components/MainLoading/MainLoading";
import AppLoading from "./components/AppLoading/AppLoading";
import Alert, { AlertType } from "./components/Alert/Alert";
import NetworkError from "./components/NetworkError/NetworkError";
import { isAccessAuthorized, UserAccessList } from "./config/userAccess";
import { NoRoleFound } from "./components/NoRoleFound/NoRoleFound";
import { DistributionDashboard } from "./containers/Assets/DistributionDashboard";
import { SCHOOL_LEVEL_ROLES } from "./components/RegisterUserAccountPage/RegisterUserAccountPage";
import { TraineeCertificateVerification } from "./containers/TraineeCertificateVerification/TraineeCertificateVerification";

//* Components

const Dashboard = lazy(() =>
  import("./containers/Dashboard/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);
const Profile = lazy(() =>
  import("./containers/Profile/Profile").then(({ Profile }) => ({
    default: Profile,
  }))
);
const ChangePassword = lazy(() =>
  import("./containers/ChangePassword/ChangePassword").then(
    ({ ChangePassword }) => ({
      default: ChangePassword,
    })
  )
);
const TrainingsList = lazy(() =>
  import("./containers/TrainingsList/TrainingsList").then(
    ({ TrainingsList }) => ({
      default: TrainingsList,
    })
  )
);
const UsersList = lazy(() =>
  import("./containers/UsersList/UsersList").then(({ UsersList }) => ({
    default: UsersList,
  }))
);

const AssetSetting = lazy(() =>
  import("./containers/Assets/AssetSettings").then(({ AssetSettings }) => ({
    default: AssetSettings,
  }))
);

const TrainingDashboard = lazy(() =>
  import("./containers/TrainingDashboard/TrainingDashboard").then(
    ({ TrainingDashboard }) => ({
      default: TrainingDashboard,
    })
  )
);

const TrainingProviderDashboard = lazy(() =>
  import("./containers/TrainingProviders/TrainingProviderDashboard").then(
    ({ TrainingProviderDashboard }) => ({
      default: TrainingProviderDashboard,
    })
  )
);

const CreateTrainingProvider = lazy(() =>
  import("./containers/TrainingProviders/CreateTrainingProvider").then(
    ({ CreateTrainingProvider }) => ({
      default: CreateTrainingProvider,
    })
  )
);

const CreateTrainingPage = lazy(() =>
  import("./containers/CreateTrainingPage/CreateTrainingPage").then(
    ({ CreateTrainingPage }) => ({
      default: CreateTrainingPage,
    })
  )
);

const TrainingOffers = lazy(() =>
  import("./containers/TrainingOffers/TrainingOffers").then(
    ({ TrainingOffers }) => ({
      default: TrainingOffers,
    })
  )
);

const AssetsList = lazy(() =>
  import("./containers/AssetsList/AssetsList").then(({ AssetsList }) => ({
    default: AssetsList,
  }))
);

const TeachersList = lazy(() =>
  import("./containers/TeachersList/TeachersList").then(({ TeachersList }) => ({
    default: TeachersList,
  }))
);

const RegisterUserAccount = lazy(() =>
  import("./components/RegisterUserAccountPage/RegisterUserAccountPage").then(
    ({ RegisterUserAccountPage }) => ({
      default: RegisterUserAccountPage,
    })
  )
);

//* Interfaces
// props for the component
interface AppProps {
  auth: Auth;
  system: System;
  FC_CheckLoggedIn: (callBack: (status: boolean) => void) => void;
  FC_Logout: () => void;
  FC_GetSystemInfo: (callback: (loading: boolean) => void) => void;
  FC_SetError: (msg: string) => void;
  FC_SetSuccess: (msg: string) => void;
}

interface AppState {
  loading: boolean;
  openSideNav: boolean;
}

class _App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loading: false,
      openSideNav: true,
    };
  }

  componentDidMount() {
    //* check if the user is logged in
    this.setState({ loading: true });
    this.props.FC_CheckLoggedIn((status: boolean) => {
      if (status === true) {
        this.setState({ loading: false });
      }
      if (
        SCHOOL_LEVEL_ROLES.find(
          (itm) =>
            this.props.auth.user?.role !== undefined &&
            itm.toString() === this.props.auth.user.role.role_id.toString()
        ) !== undefined
      ) {
        this.setState({ openSideNav: false });
      }
    });
  }

  render() {
    // constants
    const authenticationPath = "/login";

    if (this.props.auth.loading === true) {
      return <MainLoading />;
    }

    if (this.state.loading === true) {
      return <AppLoading />;
    }

    return (
      <Fragment>
        {/* <Router basename="hrtest"> */}
        <Router basename="">
          <div className="h-screen">
            {this.props.system.success !== "" && (
              <div className="fixed right-3 top-14 z-50 animate__animated animate__zoomInUp animate__fast pt-3">
                <Alert
                  alertType={AlertType.SUCCESS}
                  title={"Done successfully!"}
                  description={this.props.system.success}
                  close={() => this.props.FC_SetSuccess("")}
                  className="shadow-xl"
                />
              </div>
            )}
            {this.props.system.error !== "" && (
              <div className="fixed right-3 top-14 z-50 animate__animated animate__zoomInUp animate__fast pt-3">
                <Alert
                  alertType={AlertType.DANGER}
                  title={"Error occurred!"}
                  description={this.props.system.error}
                  close={() => this.props.FC_SetError("")}
                  className="shadow-xl"
                />
              </div>
            )}
            {/* Check connectivity */}
            {navigator.onLine === false && <NetworkError />}
            {/* {
              <NavBar
                auth={this.props.auth}
                FC_Logout={this.props.FC_Logout}
                sideNavbarStatus={this.state.openSideNav}
                setOpenVav={(status: boolean) =>
                  this.setState({ openSideNav: status })
                }
                SwitchEmployment={() => {}}
              />
            } */}
            <div
              className={`${
                this.props.auth.isAuthenticated === true
                  ? `bg-gray-200 h-full ${
                      this.state.openSideNav === true ? " w-full" : ""
                    } overflow-y-auto`
                  : ""
              }`}
              style={{ zIndex: 9 }}
            >
              {/* {this.props.auth.isAuthenticated === true &&
                this.state.openSideNav === true && (
                  <SideNavBar
                    auth={this.props.auth}
                    sideNavbarStatus={this.state.openSideNav}
                    setOpenVav={(status: boolean) =>
                      this.setState({ openSideNav: status })
                    }
                  />
                )} */}
              <div
                className={`${
                  this.props.auth.isAuthenticated === true ? "p-0" : ""
                }`}
              >
                <Switch>
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/login" component={Homepage} />
                  <Route
                    exact
                    path="/trainee/certificate/verify/:staff_code/:cohort_id"
                    component={TraineeCertificateVerification}
                  />
                  <Suspense fallback={<LazyLoading />}>
                    <ProtectedRoute
                      path="/dashboard"
                      component={Dashboard}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />

                    <ProtectedRoute
                      path="/profile"
                      component={Profile}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    <ProtectedRoute
                      path="/change-password"
                      component={ChangePassword}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.REB_VIEW_TRAININGS_LIST
                    ) === true && (
                      <ProtectedRoute
                        path="/trainings-list"
                        component={TrainingsList}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.VIEW_SYSTEM_USERS_LIST
                    ) === true && (
                      <ProtectedRoute
                        path="/users-list"
                        component={UsersList}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.VIEW_ASSET_LIST
                    ) === true && (
                      <ProtectedRoute
                        path="/distribution-dashboard"
                        component={DistributionDashboard}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.VIEW_ASSET_LIST
                    ) === true && (
                      <ProtectedRoute
                        path="/assets-settings"
                        component={AssetSetting}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.VIEW_TRAINING_DASHBOARD
                    ) === true && (
                      <ProtectedRoute
                        path="/training-dashboard"
                        component={TrainingDashboard}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.VIEW_TRAINING_PROVIDERS
                    ) === true && (
                      <ProtectedRoute
                        path="/training-providers"
                        component={TrainingProviderDashboard}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.CREATE_TRAINING_PROVIDERS
                    ) === true && (
                      <ProtectedRoute
                        path="/create-training-providers"
                        component={CreateTrainingProvider}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.CREATE_TRAINING
                    ) === true && (
                      <ProtectedRoute
                        path="/create-trainings"
                        component={CreateTrainingPage}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.TRAINING_OFFERS
                    ) === true && (
                      <ProtectedRoute
                        path="/training-offers"
                        component={TrainingOffers}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.VIEW_ASSET_LIST
                    ) === true && (
                      <ProtectedRoute
                        path="/assets-list"
                        component={AssetsList}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.REB_VIEW_TRAININGS_LIST
                    ) === true && (
                      <ProtectedRoute
                        path="/teachers-list"
                        component={TeachersList}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth,
                      UserAccessList.VIEW_SYSTEM_USERS_LIST
                    ) === true && (
                      <ProtectedRoute
                        path="/register-user-account"
                        component={RegisterUserAccount}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                  </Suspense>
                </Switch>
              </div>
            </div>
            {/* Footer */}
            {/* {this.props.auth.isAuthenticated === false && <Footer />} */}
            {this.props.auth.isAuthenticated === true &&
              this.props.auth.user?.role === undefined && (
                <NoRoleFound
                  auth={this.props.auth}
                  onContinue={() => this.props.FC_Logout()}
                />
              )}
          </div>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): {
  auth: Auth;
  system: System;
} => {
  return {
    auth,
    system,
  };
};

export const App = connect(mapStateToProps, {
  FC_CheckLoggedIn,
  FC_Logout,
  FC_GetSystemInfo,
  FC_SetError,
  FC_SetSuccess,
})(_App);
