import { Auth } from "../actions";

export enum UserAccessList {
  VIEW_SYSTEM_USERS_LIST = "VIEW_SYSTEM_USERS_LIST",
  REB_VIEW_TRAININGS_LIST = "REB_VIEW_TRAININGS_LIST",
  VIEW_TRAINING_DASHBOARD = "VIEW_TRAINING_DASHBOARD",
  CREATE_TRAINING = "CREATE_TRAINING",
  EDIT_TRAINING = "EDIT_TRAINING",
  VIEW_TRAINING_PROVIDERS = "VIEW_TRAINING_PROVIDERS",
  CREATE_TRAINING_PROVIDERS = "CREATE_TRAINING_PROVIDERS",
  TRAINING_OFFERS = "TRAINING_OFFERS",
  CHANGE_TRAINING_STATUS = "CHANGE_TRAINING_STATUS",
  REB_VALIDATE_TRAINING = "REB_VALIDATE_TRAINING",
  VIEW_ASSET_LIST = "VIEW_ASSET_LIST",
  MODIFY_ASSETS = "MODIFY_ASSETS",
  FOCAL_TEACHER = "FOCAL_TEACHER",
  SSL = "SSL",
  CREATE_COP_REPORT = "CREATE_COP_REPORT",
  VIEW_COP_REPORT = "VIEW_COP_REPORT",
}

export const isAccessAuthorized: any = (
  auth: Auth,
  access: UserAccessList
): boolean => {
  if (auth.user === null || auth.user.role === undefined) {
    return false;
  }
  if (auth.user.role.access.find((itm) => itm === access) !== undefined) {
    return true;
  }
  return false;
};
