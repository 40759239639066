const server = "https://elearning.reb.rw/rebmis";
export const API_URL = `${server}/api`;

// const server = "http://localhost:8888/projects";
// export const API_URL = `${server}/rebmis_backend`;

export const DISTRICTS_LOCATION = `${server}/locations.json`;
// export const DISTRICTS_LOCATION = `http://localhost:3000/locations.json`;

// Files location
export const IMAGES_PATH = {};
export enum DocFolder {
  docs = "docs",
}
