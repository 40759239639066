import React, { useState } from "react";
import { getObjectKeys } from "../../utils/functions";

const TableWithPagination: React.FC<{
  data: any[];
  headerTitles: string[];
  itemsPerPage: number;
}> = ({ data, itemsPerPage, headerTitles }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);

  const currentData = data.slice(startIndex, endIndex);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-full">
      <table className="min-w-full w-auto text-xs text-left">
        <thead>
          <tr>
            {headerTitles.map((item, i) => (
              <th key={i + 1} className="border p-2">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, i) => (
            <tr key={i + 1}>
              {getObjectKeys(data).map((dataItem, d) => (
                <td key={d + 1} className="border p-2 truncate w-2">
                  {item[dataItem]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row items-center gap-2 my-2">
        <button
          onClick={() => handleChangePage(currentPage - 1)}
          disabled={currentPage === 1}
          className="border border-primary-700 px-3 py-2 text-primary-800 hover:bg-primary-800 hover:text-white hover:border-white text-xs rounded-md"
        >
          Previous
        </button>
        <span className="text-sm">{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => handleChangePage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="border border-primary-700 px-3 py-2 text-primary-800 hover:bg-primary-800 hover:text-white hover:border-white text-xs rounded-md"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TableWithPagination;
