import React, { Component, Fragment } from "react";
import SearchInput from "../Fragments/SearchInput";
import ExportToExcel from "../GenerateReport/ExportToExcel";
import {
  CohortInterface,
  FC_DownloadCohortTrainingCertificates,
  FC_DownloadIndividualTrainingCertificates,
  ProgressReportInterface,
} from "../../actions";
import { search } from "../../utils/functions";
import BackButton from "../Fragments/BackButton";
import { BsDownload } from "react-icons/bs";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Loading from "../Loading/Loading";
import Alert, { AlertType } from "../Alert/Alert";
import { GeneralReportTypeEnum } from "./ElearningReport";
import { TrainingInterface } from "../TrainingItem/TrainingItem";

interface TotalAverageMarksProps {
  data: ProgressReportInterface[];
  cohorts: CohortInterface[];
  training: TrainingInterface;
}
interface TotalAverageMarksState {
  searchValue: string;
  selectedUserReport: ProgressReportInterface | null;
  onBulkDownload: boolean;
  loading: boolean;
  downloadUrl: string;
  success: string;
  error: string;
  currentPage: number;
}

export class TotalAverageMarks extends Component<
  TotalAverageMarksProps,
  TotalAverageMarksState
> {
  constructor(props: TotalAverageMarksProps) {
    super(props);
    this.state = {
      searchValue: "",
      selectedUserReport: null,
      onBulkDownload: false,
      loading: false,
      downloadUrl: "",
      success: "",
      error: "",
      currentPage: 1,
    };
  }
  FilteredData = () =>
    search(
      this.props.data,
      this.state.searchValue
    ) as ProgressReportInterface[];
  getUsersList = () => {
    var response: ProgressReportInterface[] = [];
    for (const item of this.FilteredData()) {
      if (
        response.find((itm) => itm.staff_code === item.staff_code) === undefined
      ) {
        response.push(item);
      }
    }
    return response;
  };
  getTotalAverage = (staff_code: string) => {
    var response: {
      courseNavigation: number;
      endOfChapter: number;
      CoPMarks: number;
      classroomApplication: number;
      selfAssesment: number;
      endOfModule: number;
      endOfCourse: number;
      reflectionNotes: number;
    } = {
      courseNavigation: 0,
      endOfChapter: 0,
      CoPMarks: 0,
      classroomApplication: 0,
      selfAssesment: 0,
      endOfModule: 0,
      endOfCourse: 0,
      reflectionNotes: 0,
    };
    const FilteredTemp = this.props.data.filter(
      (itm) => itm.staff_code === staff_code
    );
    console.log({ FilteredTemp: FilteredTemp });
    for (const item of FilteredTemp) {
      response = {
        courseNavigation:
          response.courseNavigation + parseFloat(item.courseNavigation),
        endOfChapter: response.endOfChapter + parseFloat(item.endOfChapter),
        CoPMarks: response.CoPMarks + parseFloat(item.copMarks),
        classroomApplication:
          response.classroomApplication + parseFloat(item.classroomApplication),
        selfAssesment: response.selfAssesment + parseFloat(item.selfAssesment),
        endOfModule: response.endOfModule + parseFloat(item.endOfModule),
        endOfCourse: response.endOfCourse + parseFloat(item.endOfCourse),
        reflectionNotes:
          response.reflectionNotes + parseFloat(item.reflectionNotes),
      };
    }
    return {
      courseNavigation: response.courseNavigation / FilteredTemp.length,
      endOfChapter: response.endOfChapter / FilteredTemp.length,
      CoPMarks: response.CoPMarks / FilteredTemp.length,
      classroomApplication: response.classroomApplication / FilteredTemp.length,
      selfAssesment: response.selfAssesment / FilteredTemp.length,
      endOfModule: response.endOfModule / FilteredTemp.length,
      endOfCourse:
        this.props.data.length > 0
          ? parseFloat(this.props.data[0].endOfCourse)
          : 0,
      reflectionNotes: response.reflectionNotes / FilteredTemp.length,
    };
  };

  getUserReport = (staff_code: string) =>
    this.props.data.filter((itm) => itm.staff_code === staff_code);
  getItemAverage = (data: ProgressReportInterface) =>
    (
      (parseFloat(data.courseNavigation) +
        parseFloat(data.endOfChapter) +
        parseFloat(data.endOfCourse) +
        parseFloat(data.endOfModule) +
        parseFloat(data.selfAssesment) +
        this.getTeacherPractice(data)) /
      6
    ).toFixed(1);

  getModules = (staff_code: string) => {
    var moduleIds: { moduleId: string; moduleName: string }[] = [];
    for (const item of this.getUserReport(staff_code)) {
      if (
        moduleIds.find((itm) => item.moduleId === itm.moduleId) === undefined
      ) {
        moduleIds = [
          ...moduleIds,
          {
            moduleId: item.moduleId,
            moduleName: item.moduleName,
          },
        ];
      }
    }
    return moduleIds;
  };

  getModuleTotals = (moduleId: string, staff_code: string) => {
    const selected = this.getUserReport(staff_code).find(
      (itm) => itm.moduleId === moduleId
    );
    if (selected !== undefined) {
      return parseFloat(selected.endOfModule);
    }
    return 0;
  };

  getTotalModuleMarks = (staff_code: string) => {
    var total: number = 0;
    for (const item of this.getModules(staff_code)) {
      total += this.getModuleTotals(item.moduleId, staff_code);
    }
    return total;
  };
  getAverageUserReport = (staff_code: string) => {
    return (
      (this.getUserReport(staff_code).length > 0
        ? (parseFloat(this.getUserReport(staff_code)[0].endOfCourse) * 20) / 100
        : 0) +
      ((this.getTotalModuleMarks(staff_code) /
        this.getModules(staff_code).length) *
        30) /
        100 +
      ((this.getTotal(GeneralReportTypeEnum.endOfChapter, staff_code) /
        this.getUserReport(staff_code).length) *
        10) /
        100 +
      +(
        ((this.getTotal(GeneralReportTypeEnum.courseNavigation, staff_code) /
          this.getUserReport(staff_code).length) *
          20) /
        100
      ) +
      ((this.getTotal(GeneralReportTypeEnum.selfAssesment, staff_code) /
        this.getUserReport(staff_code).length) *
        10) /
        100 +
      (parseFloat(this.getTotalTeacherPractice(staff_code)) * 10) / 100
    ).toFixed(0);
  };
  // getAverageUserReport = (staff_code: string) => {
  //   var total = 0;
  //   for (const item of this.getUserReport(staff_code)) {
  //     total += parseFloat(this.getItemAverage(item));
  //   }
  //   return (total / this.getUserReport(staff_code).length).toFixed(0);
  // };
  DownloadCertificates = (cohortId: string, cohort_name: string) => {
    this.setState({ loading: true, downloadUrl: "", success: "", error: "" });
    FC_DownloadCohortTrainingCertificates(
      cohortId,
      `${this.props.training.trainingName}_${cohort_name}`,
      (
        loading: boolean,
        res: {
          type: "success" | "error";
          msg: string;
          downloadUrl: string;
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "success") {
          this.setState({ success: res.msg, downloadUrl: res.downloadUrl });
          setTimeout(() => {
            this.setState({ loading: false, onBulkDownload: false });
          }, 500);
        }
        if (res?.type === "error") {
          this.setState({ error: res.msg });
        }
      }
    );
  };
  DownloadUserCertificates = (
    staff_code: string,
    cohortId: string,
    staff_name: string
  ) => {
    this.setState({ loading: true, downloadUrl: "", success: "", error: "" });
    FC_DownloadIndividualTrainingCertificates(
      staff_code,
      cohortId,
      `${this.props.training.trainingName}_${staff_name}`,
      (
        loading: boolean,
        res: {
          type: "success" | "error";
          msg: string;
          downloadUrl: string;
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "success") {
          this.setState({ success: res.msg, downloadUrl: res.downloadUrl });
          setTimeout(() => {
            this.setState({ loading: false });
          }, 500);
        }
        if (res?.type === "error") {
          this.setState({ error: res.msg });
        }
      }
    );
  };

  getTeacherPractice = (data: ProgressReportInterface) =>
    parseFloat(
      (
        (parseFloat(data.copMarks) +
          parseFloat(data.classroomApplication) +
          parseFloat(data.reflectionNotes) +
          parseFloat(data.selfStudy) +
          parseFloat(data.coaching)) /
        5
      ).toFixed(1)
    );

  getTotalTeacherPractice = (staff_code: string) => {
    var total: number = 0;
    for (const item of this.getUserReport(staff_code)) {
      total += this.getTeacherPractice(item);
    }
    return (total / this.getUserReport(staff_code).length).toFixed(1);
  };

  getTotal = (type: GeneralReportTypeEnum, staff_code: string) => {
    var total: number = 0;
    for (const item of this.getUserReport(staff_code)) {
      switch (type) {
        case GeneralReportTypeEnum.courseNavigation:
          total += parseFloat(item.courseNavigation);
          break;
        case GeneralReportTypeEnum.copMarks:
          total += parseFloat(item.copMarks);
          break;
        case GeneralReportTypeEnum.selfAssesment:
          total += parseFloat(item.selfAssesment);
          break;
        case GeneralReportTypeEnum.classroomApplication:
          total += parseFloat(item.classroomApplication);
          break;
        case GeneralReportTypeEnum.reflectionNotes:
          total += parseFloat(item.reflectionNotes);
          break;
        case GeneralReportTypeEnum.endOfChapter:
          total += parseFloat(item.endOfChapter);
          break;
        case GeneralReportTypeEnum.endOfModule:
          total += parseFloat(item.endOfModule);
          break;
        default:
          total += parseFloat(item.endOfCourse);
      }
    }
    return total;
  };

  render() {
    const itemsPerPage = 20;

    const totalPages = Math.ceil(this.getUsersList().length / itemsPerPage);

    const startIndex = (this.state.currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(
      startIndex + itemsPerPage,
      this.getUsersList().length
    );

    const currentData = this.getUsersList().slice(startIndex, endIndex);

    const handleChangePage = (page: number) => {
      this.setState({ currentPage: page });
    };
    return (
      <Fragment>
        <div className="p-1 bg-white rounded-md">
          <div className="">
            <div className="font-bold text-lg mb-2 px-3 pt-2">Tabular Data</div>
            <div className="flex flex-row items-center justify-between w-full gap-2 mb-3 px-1">
              <SearchInput
                searchData={this.state.searchValue}
                onChange={(value: string) =>
                  this.setState({ searchValue: value })
                }
              />
              <div className="relative">
                <div
                  onClick={() =>
                    this.setState({
                      onBulkDownload: !this.state.onBulkDownload,
                    })
                  }
                  className="px-4 py-2 text-center w-44 rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer text-sm"
                >
                  Bulk Certificates
                </div>
                {this.state.onBulkDownload === true && (
                  <div
                    className="absolute pt-1 right-0 z-10 shadow-xl animate__animated animate__fadeInUp animate__faster"
                    style={{ minWidth: "500px" }}
                  >
                    <div className="bg-white rounded-md p-3">
                      <div className="mb-1 -mt-1 flex flex-row items-center gap-2">
                        <BackButton
                          title={"Back"}
                          className="text-sm bg-accent-200 text-accent-900 rounded-md"
                          onClick={() =>
                            this.setState({ onBulkDownload: false })
                          }
                        />
                        <div className="font-bold text-base">Choose cohort</div>
                      </div>
                      <div className="rounded-md overflow-hidden border border-gray-100">
                        {this.state.success !== "" && (
                          <div className="py-2">
                            <Alert
                              alertType={AlertType.SUCCESS}
                              title={this.state.success}
                              close={() =>
                                this.setState({ success: "", error: "" })
                              }
                            />
                          </div>
                        )}
                        {this.state.error !== "" && (
                          <div className="py-2">
                            <Alert
                              alertType={AlertType.DANGER}
                              title={this.state.error}
                              close={() =>
                                this.setState({ success: "", error: "" })
                              }
                            />
                          </div>
                        )}
                        {this.state.loading === true ? (
                          <div className="pt-3">
                            <Loading />
                          </div>
                        ) : (
                          this.props.cohorts.map((item, i) => (
                            <div
                              key={i + 1}
                              className={`p-3 py-2 w-full text-xs cursor-pointer bg-white text-black hover:bg-accent-200 hover:text-accent-900 ${
                                this.props.cohorts[0].cohortId === item.cohortId
                                  ? ""
                                  : "border-t"
                              } border-gray-100 flex flex-row items-center justify-between gap-2 group`}
                              onClick={() =>
                                this.DownloadCertificates(
                                  item.cohortId,
                                  item.cohortName
                                )
                              }
                            >
                              <div className="flex flex-row items-center gap-2">
                                <div>
                                  <IoIosCheckmarkCircleOutline className="text-lg text-gray-400 group-hover:text-black" />
                                </div>
                                <div>{item.cohortName}</div>
                              </div>
                              <div>
                                <BsDownload className="text-lg text-accent-900 group-hover:text-black" />
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <ExportToExcel
                fileData={this.getUsersList().map((item, i) => ({
                  "#": i + 1,
                  "Staff Code": item.staff_code,
                  "Full Name": item.traineeName,
                  Gender: item.gender,
                  Age: item.age,
                  "School Code": item.school_code,
                  "School Name": item.school_name,
                  District: item.district_name,
                  Sector: item.sector_name,
                  "Course Navigation(%)": `${this.getTotalAverage(
                    item.staff_code
                  ).courseNavigation.toFixed(1)}`,
                  "End of Chapter(%)": `${this.getTotalAverage(
                    item.staff_code
                  ).endOfChapter.toFixed(1)}`,
                  "End of Module(%)": `${this.getTotalModuleMarks(
                    item.staff_code
                  ).toFixed(1)}`,
                  "End of Course(%)": `${item.endOfCourse}`,
                  "Self Assessment(%)": `${this.getTotalAverage(
                    item.staff_code
                  ).selfAssesment.toFixed(1)}`,
                  "Teacher Practices": this.getTeacherPractice(item),
                  "Average Marks(%)": `${this.getAverageUserReport(
                    item.staff_code
                  )}`,
                }))}
                fileName={`Average marks report details`}
                btnName="Download Report"
              />
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <table className="min-w-full w-auto text-xs text-left">
              <thead>
                <tr>
                  <th className="p-2 border text-center">#</th>
                  <th className="p-2 border">Staff Code</th>
                  <th className="p-2 border">Full Name</th>
                  <th className="p-2 border">Gender</th>
                  <th className="p-2 border">Age</th>
                  <th className="p-2 border truncate">School Code</th>
                  <th className="p-2 border truncate">School Name</th>
                  <th className="p-2 border">District</th>
                  <th className="p-2 border">Sector</th>
                  <th className="p-2 border truncate">Average Marks(%)</th>
                  <th className="p-2 border truncate">End of Course</th>
                  <th className="p-2 border">End of Module</th>
                  <th className="p-2 border">End of Chapter</th>
                  <th className="p-2 border">Self Assessment</th>
                  <th className="p-2 border truncate">Course Navigation</th>
                  <th className="p-2 border truncate">Teacher Practices</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, i) => (
                  <tr
                    key={i + 1}
                    className="group cursor-pointer hover:text-accent-900 hover:bg-accent-200 relative"
                    onClick={() =>
                      this.setState({
                        selectedUserReport:
                          this.state.selectedUserReport?.staff_code ===
                          item.staff_code
                            ? null
                            : item,
                      })
                    }
                  >
                    <td className="p-2 border text-center">{i + 1}</td>
                    <td className="p-2 border truncate">{item.staff_code}</td>
                    <td className="p-2 border truncate">{item.traineeName}</td>
                    <td className="p-2 border truncate">{item.gender}</td>
                    <td className="p-2 border truncate">{item.age}</td>
                    <td className="p-2 border truncate">{item.school_code}</td>
                    <td className="p-2 border truncate">{item.school_name}</td>
                    <td className="p-2 border">{item.district_name}</td>
                    <td className="p-2 border">{item.sector_name}</td>
                    <td
                      className={`p-2 border ${
                        parseFloat(
                          this.getAverageUserReport(item.staff_code)
                        ) <= 0
                          ? "text-red-600 bg-red-50 group-hover:bg-red-600 group-hover:text-white"
                          : parseFloat(
                              this.getAverageUserReport(item.staff_code)
                            ) < 50
                          ? "text-accent-900"
                          : "text-primary-800 group-hover:bg-primary-800 group-hover:text-white"
                      }`}
                    >
                      {this.getAverageUserReport(item.staff_code)}%
                    </td>
                    <td className="p-2 border">
                      {parseFloat(item.endOfCourse).toFixed(1)}
                    </td>
                    <td className="p-2 border">
                      {this.getTotalModuleMarks(item.staff_code).toFixed(1)}
                    </td>
                    <td className="p-2 border">
                      {this.getTotalAverage(
                        item.staff_code
                      ).endOfChapter.toFixed(1)}
                    </td>
                    <td className="p-2 border">
                      {this.getTotalAverage(
                        item.staff_code
                      ).selfAssesment.toFixed(1)}
                    </td>
                    <td className="p-2 border">
                      {this.getTotalAverage(
                        item.staff_code
                      ).courseNavigation.toFixed(1)}
                    </td>
                    <td className="p-2 border">
                      {this.getTeacherPractice(item)}
                    </td>
                    <>
                      {this.state.selectedUserReport !== null &&
                        this.state.selectedUserReport.staff_code ===
                          item.staff_code && (
                          <div className="bg-white rounded-md absolute top-2 left-2 right-2 z-10 p-3 shadow-xl text-black">
                            <div className="">
                              <div className="flex flex-row items-center gap-2 mb-2">
                                <BackButton
                                  title="Back"
                                  className="bg-accent-200 text-accent-900 hover:bg-accent-400 text-sm"
                                  onClick={() =>
                                    this.setState({
                                      selectedUserReport: null,
                                      error: "",
                                      success: "",
                                    })
                                  }
                                />
                                <div className="font-light text-lg">
                                  SELECTED:{" "}
                                  <span className="font-bold">
                                    {this.state.selectedUserReport.traineeName}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{ width: "1203px", overflowX: "auto" }}
                              >
                                <table
                                  className="min-w-full text-xs text-left"
                                  style={{ zIndex: 9 }}
                                >
                                  <thead>
                                    <tr className="text-xs">
                                      <th className="border p-2 truncate">
                                        Course
                                      </th>
                                      <th className="border p-2 truncate">
                                        Module
                                      </th>
                                      <th className="border p-2 truncate">
                                        Chapter
                                      </th>
                                      <th className="border p-2 truncate">
                                        End of Course
                                      </th>
                                      <th className="border p-2 truncate">
                                        End of Module
                                      </th>
                                      <th className="border p-2 truncate">
                                        End of Chapter
                                      </th>
                                      <th className="border p-2 truncate">
                                        Course Navigation
                                      </th>
                                      <th className="border p-2 truncate">
                                        Self Assessment
                                      </th>
                                      <th className="border p-2 truncate">
                                        Teacher Practice
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="bg-gray-50">
                                      <td className="border p-2 truncate font-bold">
                                        {"Literacy Acquisition"}
                                      </td>
                                      <td
                                        colSpan={2}
                                        className="border p-2"
                                      ></td>
                                      <td className="border p-2 font-bold">
                                        {this.getUserReport(
                                          this.state.selectedUserReport
                                            .staff_code
                                        ).length > 0 &&
                                          this.getUserReport(
                                            this.state.selectedUserReport
                                              .staff_code
                                          )[0].endOfCourse}
                                      </td>
                                      <td
                                        colSpan={5}
                                        className="border p-2"
                                      ></td>
                                    </tr>
                                    <tr>
                                      <td
                                        rowSpan={
                                          (
                                            this.getUserReport(
                                              this.state.selectedUserReport
                                                .staff_code
                                            ) ?? []
                                          ).length +
                                          this.getModules(
                                            this.state.selectedUserReport
                                              .staff_code
                                          ).length *
                                            2 +
                                          1
                                        }
                                        className="border p-2"
                                      ></td>
                                    </tr>
                                    {this.state.selectedUserReport !== null &&
                                      this.getModules(
                                        this.state.selectedUserReport.staff_code
                                      ).map((module, m) => (
                                        <>
                                          <tr>
                                            <td className="border p-2 text-primary-800">
                                              {module.moduleName}
                                            </td>
                                            <td
                                              colSpan={2}
                                              className="border p-2"
                                            ></td>
                                            <td className="border p-2 text-primary-800">
                                              {this.state.selectedUserReport !==
                                                null &&
                                                this.getModuleTotals(
                                                  module.moduleId,
                                                  this.state.selectedUserReport!
                                                    .staff_code
                                                )}
                                            </td>
                                            <td
                                              colSpan={4}
                                              className="border p-2"
                                            ></td>
                                          </tr>
                                          <tr key={m + 1}>
                                            <td
                                              rowSpan={
                                                (
                                                  this.getUserReport(
                                                    this.state
                                                      .selectedUserReport!
                                                      .staff_code
                                                  ) ?? []
                                                ).filter(
                                                  (itm) =>
                                                    itm.moduleId ===
                                                    module.moduleId
                                                ).length + 1
                                              }
                                              className="border p-2 truncate"
                                            ></td>
                                          </tr>
                                          {(
                                            this.getUserReport(
                                              this.state.selectedUserReport!
                                                .staff_code
                                            ) ?? []
                                          )
                                            .filter(
                                              (itm) =>
                                                itm.moduleId === module.moduleId
                                            )
                                            .map((item, i) => (
                                              <tr key={(i = 1)}>
                                                <td className="border p-2 truncate">
                                                  {item.chapterName}
                                                </td>
                                                <td className="border p-2"></td>
                                                <td className="border p-2"></td>
                                                <td className="border p-2">
                                                  {item.endOfChapter}
                                                </td>
                                                <td className="border p-2">
                                                  {item.courseNavigation}
                                                </td>
                                                <td className="border p-2">
                                                  {item.selfAssesment}
                                                </td>
                                                <td className="border p-2">
                                                  {this.getTeacherPractice(
                                                    item
                                                  )}
                                                </td>
                                              </tr>
                                            ))}
                                        </>
                                      ))}

                                    <tr className="font-bold">
                                      <td
                                        colSpan={3}
                                        className="border p-2 font-bold"
                                      >
                                        Total (%)
                                      </td>
                                      <td className="border p-2">
                                        {this.getUserReport(
                                          this.state.selectedUserReport
                                            .staff_code
                                        ) !== null &&
                                          this.getUserReport(
                                            this.state.selectedUserReport
                                              .staff_code
                                          ).length > 0 &&
                                          this.getUserReport(
                                            this.state.selectedUserReport
                                              .staff_code
                                          )[0].endOfCourse}
                                      </td>
                                      <td className="border p-2">
                                        {" "}
                                        {(
                                          this.getTotalModuleMarks(
                                            this.state.selectedUserReport
                                              .staff_code
                                          ) /
                                          this.getModules(
                                            this.state.selectedUserReport
                                              .staff_code
                                          ).length
                                        ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {(
                                          this.getTotal(
                                            GeneralReportTypeEnum.endOfChapter,
                                            this.state.selectedUserReport
                                              .staff_code
                                          ) /
                                          (
                                            this.getUserReport(
                                              this.state.selectedUserReport
                                                .staff_code
                                            ) ?? []
                                          ).length
                                        ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {(
                                          this.getTotal(
                                            GeneralReportTypeEnum.courseNavigation,
                                            this.state.selectedUserReport
                                              .staff_code
                                          ) /
                                          (
                                            this.getUserReport(
                                              this.state.selectedUserReport
                                                .staff_code
                                            ) ?? []
                                          ).length
                                        ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {(
                                          this.getTotal(
                                            GeneralReportTypeEnum.selfAssesment,
                                            this.state.selectedUserReport
                                              .staff_code
                                          ) /
                                          (
                                            this.getUserReport(
                                              this.state.selectedUserReport
                                                .staff_code
                                            ) ?? []
                                          ).length
                                        ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {this.getTotalTeacherPractice(
                                          this.state.selectedUserReport
                                            .staff_code
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="font-bold text-primary-800">
                                      <td
                                        colSpan={3}
                                        className="border p-2 font-bold"
                                      >
                                        Points
                                      </td>
                                      <td className="border p-2">
                                        {this.getUserReport(
                                          this.state.selectedUserReport
                                            .staff_code
                                        ) !== null &&
                                          this.getUserReport(
                                            this.state.selectedUserReport
                                              .staff_code
                                          ).length > 0 &&
                                          (
                                            (parseFloat(
                                              this.getUserReport(
                                                this.state.selectedUserReport
                                                  .staff_code
                                              )[0].endOfCourse
                                            ) *
                                              20) /
                                            100
                                          ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {" "}
                                        {(
                                          ((this.getTotalModuleMarks(
                                            this.state.selectedUserReport
                                              .staff_code
                                          ) /
                                            this.getModules(
                                              this.state.selectedUserReport
                                                .staff_code
                                            ).length) *
                                            30) /
                                          100
                                        ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {(
                                          ((this.getTotal(
                                            GeneralReportTypeEnum.endOfChapter,
                                            this.state.selectedUserReport
                                              .staff_code
                                          ) /
                                            (
                                              this.getUserReport(
                                                this.state.selectedUserReport
                                                  .staff_code
                                              ) ?? []
                                            ).length) *
                                            10) /
                                          100
                                        ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {(
                                          ((this.getTotal(
                                            GeneralReportTypeEnum.courseNavigation,
                                            this.state.selectedUserReport
                                              .staff_code
                                          ) /
                                            (
                                              this.getUserReport(
                                                this.state.selectedUserReport
                                                  .staff_code
                                              ) ?? []
                                            ).length) *
                                            20) /
                                          100
                                        ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {(
                                          ((this.getTotal(
                                            GeneralReportTypeEnum.selfAssesment,
                                            this.state.selectedUserReport
                                              .staff_code
                                          ) /
                                            (
                                              this.getUserReport(
                                                this.state.selectedUserReport
                                                  .staff_code
                                              ) ?? []
                                            ).length) *
                                            10) /
                                          100
                                        ).toFixed(1)}
                                      </td>
                                      <td className="border p-2">
                                        {(parseFloat(
                                          this.getTotalTeacherPractice(
                                            this.state.selectedUserReport
                                              .staff_code
                                          )
                                        ) *
                                          10) /
                                          100}
                                      </td>
                                    </tr>
                                    <tr className="text-primary-800 bg-primary-50">
                                      <td
                                        colSpan={8}
                                        className="border p-2 font-bold text-right text-sm"
                                      >
                                        Total Marks
                                      </td>
                                      <td className="border p-2 font-bold text-sm">
                                        {this.getAverageUserReport(
                                          this.state.selectedUserReport
                                            .staff_code
                                        )}
                                        %
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                {this.state.success !== "" && (
                                  <div className="py-2">
                                    <Alert
                                      alertType={AlertType.SUCCESS}
                                      title={this.state.success}
                                      close={() =>
                                        this.setState({
                                          success: "",
                                          error: "",
                                        })
                                      }
                                    />
                                  </div>
                                )}
                                {this.state.error !== "" && (
                                  <div className="py-2">
                                    <Alert
                                      alertType={AlertType.DANGER}
                                      title={this.state.error}
                                      close={() =>
                                        this.setState({
                                          success: "",
                                          error: "",
                                        })
                                      }
                                    />
                                  </div>
                                )}
                                {this.state.loading === true ? (
                                  <div className="pt-3">
                                    {" "}
                                    <Loading />
                                  </div>
                                ) : (
                                  <div className="flex flex-row items-center justify-end py-2">
                                    {this.state.selectedUserReport !== null &&
                                    parseFloat(
                                      this.getAverageUserReport(
                                        this.state.selectedUserReport.staff_code
                                      )
                                    ) >= 60 ? (
                                      <div
                                        onClick={() =>
                                          this.DownloadUserCertificates(
                                            item.staff_code,
                                            item.cohortId,
                                            item.traineeName
                                          )
                                        }
                                        className="px-4 py-2 text-sm bg-green-600 text-white cursor-pointer w-max rounded-md"
                                      >
                                        Generate Certificate
                                      </div>
                                    ) : (
                                      <div
                                        className="px-4 py-2 text-sm bg-gray-400 text-white cursor-not-allowed w-max rounded-md"
                                        title="Certificate is available for 70% and above average marks"
                                      >
                                        Certificate is not available for marks
                                        below 60%
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="w-full">
              {/* Next and Previous */}
              <div className="flex flex-row items-center gap-2 my-2">
                <button
                  onClick={() => handleChangePage(this.state.currentPage - 1)}
                  disabled={this.state.currentPage === 1}
                  className="border border-primary-700 px-3 py-2 text-primary-800 hover:bg-primary-800 hover:text-white hover:border-white text-xs rounded-md"
                >
                  Previous
                </button>
                <span className="text-sm">{`Page ${this.state.currentPage} of ${totalPages}`}</span>
                <button
                  onClick={() => handleChangePage(this.state.currentPage + 1)}
                  disabled={this.state.currentPage === totalPages}
                  className="border border-primary-700 px-3 py-2 text-primary-800 hover:bg-primary-800 hover:text-white hover:border-white text-xs rounded-md"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
